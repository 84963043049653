<template>
  <div class="body">
    <svg id="gebit" ref="denture" width="100%" height="40vh" style="left: 0px; top: 0px;">
        <g id="boven" :transform="transformUpper" fill="" stroke="none"></g>
        <g id="beneden" :transform="transformLower" fill="#000000" stroke="none"></g>
    </svg>
  </div>

</template>

<style>

#boven {
  zoom: 0.1 !important;
}

div.tooltip {
  font-family: Nexa;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  position: absolute;
  max-width: 200px;
  padding: 8px;
  margin-right:-50px;
  transform: translateX(-50%);
  background: #003444;
  border-radius: 4px;
  z-index: 1000;
  border: 0px;
  pointer-events: none;
}

ul {
  padding-left: 15px;
}

</style>

<script>

import * as d3 from 'd3'

var elementen = [
  { key: '21', d: 'M3655 5229 c-44 -10 -105 -18 -136 -18 -63 -1 -217 -31 -319 -61 -87 -26 -133 -59 -160 -116 -20 -39 -22 -56 -17 -117 4 -39 16 -102 29 -140 20 -61 31 -77 108 -152 47 -46 109 -111 138 -144 71 -80 151 -121 236 -121 75 0 142 30 166 75 8 17 28 48 42 71 63 98 147 284 158 346 27 170 -3 317 -75 364 -42 27 -85 30 -170 13z' },
  { key: '11', d: 'M4042 5237 c-18 -5 -46 -25 -61 -43 -25 -27 -30 -45 -36 -109 -4 -42 -5 -93 -3 -113 8 -59 86 -248 139 -336 155 -258 157 -260 284 -261 119 0 190 41 263 155 20 30 61 86 93 125 70 87 91 127 118 234 28 114 27 137 -11 193 -26 38 -43 51 -88 68 -108 39 -280 72 -405 75 -66 2 -138 9 -160 14 -49 13 -90 12 -133 -2z' },
  { key: '12', d: 'M4960 5024 c-80 -35 -93 -63 -110 -247 -11 -128 -9 -144 41 -300 44 -136 87 -176 188 -177 61 0 108 23 142 71 11 16 42 40 67 53 132 67 252 306 197 390 -30 45 -198 132 -400 207 -61 22 -80 23 -125 3z' },
  { key: '22', d: 'M2820 4983 c-43 -8 -173 -65 -225 -98 -27 -18 -72 -45 -100 -60 -27 -16 -63 -42 -79 -57 -27 -27 -28 -33 -23 -86 11 -121 80 -239 176 -304 27 -18 68 -57 91 -87 50 -63 95 -85 162 -79 94 9 152 77 178 205 5 27 16 73 25 103 24 88 16 215 -21 335 -26 85 -109 143 -184 128z' },
  { key: '13', d: 'M5637 4659 c-77 -18 -166 -77 -191 -128 -40 -80 -96 -321 -113 -488 -10 -101 66 -199 151 -196 59 1 241 21 311 34 87 16 193 68 237 117 66 74 86 213 52 365 -32 143 -84 213 -198 268 -69 34 -89 39 -145 38 -37 -1 -83 -5 -104 -10z' },
  { key: '23', d: 'M2170 4601 c-80 -9 -164 -53 -206 -110 -54 -71 -145 -221 -161 -266 -36 -102 -5 -213 84 -303 42 -42 61 -53 151 -81 56 -18 139 -39 185 -46 45 -7 101 -22 124 -34 66 -33 112 -24 171 32 62 59 79 120 57 194 -8 26 -19 96 -25 154 -11 111 -30 189 -61 251 -33 65 -117 157 -167 182 -60 30 -84 34 -152 27z' },
  { key: '14', d: 'M6120 4014 c-36 -21 -88 -58 -116 -84 -27 -26 -66 -55 -85 -65 -87 -44 -138 -95 -179 -179 -50 -101 -71 -214 -50 -275 18 -56 65 -97 125 -112 28 -6 71 -20 98 -31 103 -41 242 -20 414 62 232 111 232 111 273 163 22 27 43 62 47 76 4 14 2 74 -4 134 -13 125 -40 191 -106 259 -67 69 -104 83 -237 86 l-115 3 -65 -37z' },
  { key: '24', d: 'M1487 3956 c-97 -35 -175 -134 -198 -251 -13 -69 6 -238 31 -284 23 -40 112 -121 134 -121 6 0 37 -14 69 -31 32 -16 99 -46 150 -66 82 -32 103 -36 192 -37 98 -1 102 0 185 40 47 23 99 47 116 53 38 15 89 66 103 104 17 44 14 139 -6 167 -97 146 -180 240 -232 262 -25 10 -96 53 -156 94 l-110 75 -120 4 c-83 3 -131 0 -158 -9z' },
  { key: '15', d: 'M6590 3416 c-25 -7 -56 -19 -70 -27 -187 -105 -259 -151 -292 -185 -116 -120 -163 -298 -104 -398 48 -81 149 -143 282 -171 98 -21 218 1 339 62 22 11 63 28 92 37 62 21 142 82 174 134 20 33 24 53 27 169 4 130 3 134 -26 197 -36 77 -70 105 -185 151 -100 41 -174 50 -237 31z' },
  { key: '25', d: 'M1195 3295 c-121 -22 -175 -48 -220 -105 -66 -83 -78 -140 -57 -282 14 -94 48 -164 101 -210 47 -40 71 -53 201 -106 120 -50 173 -62 285 -64 l90 -2 73 44 c131 79 182 152 182 262 0 123 -123 304 -241 354 -24 10 -82 38 -129 61 -68 35 -178 67 -210 62 -3 0 -36 -7 -75 -14z' },
  { key: '16', d: 'M6955 2735 c-116 -22 -206 -73 -438 -250 -136 -103 -148 -116 -210 -220 -67 -115 -63 -199 16 -298 37 -46 42 -57 83 -192 32 -106 51 -137 108 -172 22 -14 52 -36 66 -49 51 -47 263 -70 367 -39 55 16 143 73 264 169 42 34 101 80 132 104 78 59 104 108 128 239 32 174 9 405 -51 502 -32 51 -162 176 -213 204 -38 21 -146 22 -252 2z', color: 'yellow' },
  { key: '26', d: 'M876 2588 c-27 -5 -67 -22 -90 -37 -65 -45 -167 -161 -195 -221 -23 -51 -25 -68 -29 -252 -3 -108 -2 -212 2 -230 3 -18 24 -60 45 -93 43 -66 108 -118 238 -190 45 -25 106 -67 135 -94 38 -34 74 -55 128 -74 97 -34 142 -40 214 -27 99 16 171 58 273 156 99 97 111 122 99 209 -6 47 24 168 45 181 20 12 59 109 59 147 0 48 -21 89 -77 152 -25 28 -58 64 -74 82 -15 17 -61 51 -101 74 -40 23 -104 62 -143 86 -38 24 -92 50 -119 58 -28 9 -84 28 -126 44 -84 32 -201 44 -284 29z' },
  { key: '17', d: 'M7241 1629 c-25 -5 -81 -32 -130 -63 -99 -62 -149 -88 -206 -106 -69 -23 -118 -53 -169 -104 -53 -55 -79 -106 -97 -193 -18 -85 -6 -139 46 -214 24 -35 67 -102 96 -149 57 -92 127 -168 180 -195 55 -29 197 -46 294 -34 224 25 321 66 397 167 67 89 81 143 73 290 -8 160 -24 265 -51 340 -39 109 -145 205 -264 241 -89 26 -118 29 -169 20z' },
  { key: '18', d: 'M6941 3169 c-25 -5 -81 -32 -130 -63 -99 -62 -149 -88 -206 -106 -69 -23 -118 -53 -169 -104 -53 -55 -79 -106 -97 -193 -18 -85 -6 -139 46 -214 24 -35 67 -102 96 -149 57 -92 127 -168 180 -195 55 -29 197 -46 294 -34 224 25 321 66 397 167 67 89 81 143 73 290 -8 160 -24 265 -51 340 -39 109 -145 205 -264 241 -89 26 -118 29 -169 20z', rotate: -20 },
  { key: '27', d: 'M645 1467 c-56 -14 -176 -138 -199 -204 -22 -63 -31 -232 -18 -305 12 -63 94 -251 124 -285 11 -11 52 -31 91 -44 63 -20 92 -23 247 -23 165 -1 180 1 255 26 106 36 163 75 212 145 55 81 103 193 110 255 l5 53 -74 70 c-54 51 -113 92 -214 149 -76 44 -164 94 -196 113 -45 28 -75 37 -155 49 -113 16 -133 16 -188 1z' },
  { key: '28', d: 'M585 567 c-56 -14 -176 -138 -199 -204 -22 -63 -31 -232 -18 -305 12 -63 94 -251 124 -285 11 -11 52 -31 91 -44 63 -20 92 -23 247 -23 165 -1 180 1 255 26 106 36 163 75 212 145 55 81 103 193 110 255 l5 53 -74 70 c-54 51 -113 92 -214 149 -76 44 -164 94 -196 113 -45 28 -75 37 -155 49 -113 16 -133 16 -188 1z' },
  { key: '47', d: 'M7080 5054 c-275 -41 -369 -116 -405 -322 -10 -53 -29 -131 -44 -172 -35 -103 -37 -266 -3 -351 38 -94 130 -168 232 -184 25 -3 92 -18 150 -32 82 -20 120 -24 173 -20 80 7 226 49 283 82 64 37 141 127 148 172 11 72 21 269 21 398 0 129 0 131 -36 205 -50 103 -104 161 -191 205 -64 32 -78 35 -152 34 -44 -1 -124 -7 -176 -15z' },
  { key: '48', d: 'M7780 5494 c-275 -41 -369 -116 -405 -322 -10 -53 -29 -131 -44 -172 -35 -103 -37 -266 -3 -351 38 -94 130 -168 232 -184 25 -3 92 -18 150 -32 82 -20 120 -24 173 -20 80 7 226 49 283 82 64 37 141 127 148 172 11 72 21 269 21 398 0 129 0 131 -36 205 -50 103 -104 161 -191 205 -64 32 -78 35 -152 34 -44 -1 -124 -7 -176 -15z', rotate: 5 },
  { key: '37', d: 'M955 5049 c-97 -12 -146 -33 -205 -86 -94 -84 -156 -217 -186 -402 -14 -85 -15 -111 -5 -168 7 -38 12 -103 11 -146 -2 -121 45 -196 141 -228 19 -7 45 -20 59 -29 14 -9 68 -34 120 -56 86 -35 104 -39 185 -39 77 0 98 4 145 27 30 14 98 36 150 48 52 13 106 28 121 35 48 25 107 101 126 162 25 80 23 223 -5 328 -11 44 -26 123 -32 175 -14 120 -25 164 -57 218 -46 80 -154 129 -344 157 -116 16 -121 16 -224 4z' },
  { key: '38', d: 'M1255 6149 c-97 -12 -146 -33 -205 -86 -94 -84 -156 -217 -186 -402 -14 -85 -15 -111 -5 -168 7 -38 12 -103 11 -146 -2 -121 45 -196 141 -228 19 -7 45 -20 59 -29 14 -9 68 -34 120 -56 86 -35 104 -39 185 -39 77 0 98 4 145 27 30 14 98 36 150 48 52 13 106 28 121 35 48 25 107 101 126 162 25 80 23 223 -5 328 -11 44 -26 123 -32 175 -14 120 -25 164 -57 218 -46 80 -154 129 -344 157 -116 16 -121 16 -224 4z', rotate: 5 },
  { key: '46', d: 'M6685 4009 c-141 -27 -224 -114 -275 -289 -12 -41 -33 -109 -46 -150 -36 -116 -55 -217 -57 -305 -2 -73 1 -87 30 -145 38 -75 81 -114 191 -173 273 -145 363 -164 514 -108 118 44 205 115 261 213 34 58 95 264 109 362 15 110 7 151 -45 258 -61 124 -147 220 -232 258 -121 54 -361 97 -450 79z' },
  { key: '36', d: 'M1455 3951 c-203 -40 -316 -85 -351 -139 -10 -15 -50 -60 -91 -102 -76 -78 -84 -93 -104 -207 -9 -51 -8 -77 5 -140 24 -111 53 -215 89 -311 26 -71 38 -89 81 -125 93 -79 125 -98 203 -128 115 -43 218 -41 349 5 109 39 218 101 280 158 94 86 124 231 85 408 -72 326 -103 411 -186 493 -97 98 -194 121 -360 88z' },
  { key: '45', d: 'M6294 2871 c-83 -39 -126 -80 -161 -153 -56 -114 -69 -247 -33 -326 23 -51 149 -192 204 -228 120 -80 341 -76 467 8 51 34 101 110 120 181 18 68 3 190 -33 264 -48 98 -89 139 -191 189 -50 24 -96 49 -103 55 -15 13 -136 39 -179 39 -16 0 -57 -13 -91 -29z' },
  { key: '35', d: 'M1800 2834 c-77 -16 -214 -80 -271 -126 -80 -64 -129 -184 -129 -313 0 -72 4 -90 31 -144 35 -69 70 -100 184 -159 68 -36 73 -37 176 -37 120 0 124 2 281 118 137 101 167 171 150 339 -18 164 -73 253 -199 317 -50 25 -125 27 -223 5z', color: 'red' },
  { key: '34', d: 'M2408 2215 c-2 -3 -47 -14 -99 -24 -112 -24 -220 -56 -271 -82 -20 -10 -61 -43 -91 -72 -52 -50 -55 -57 -67 -127 -7 -40 -10 -94 -7 -119 14 -113 87 -231 164 -264 120 -51 202 -79 233 -79 45 0 144 33 171 58 38 33 100 123 115 167 28 77 78 321 78 384 1 74 -19 107 -84 141 -40 20 -128 31 -142 17z' },
  { key: '44', d: 'M5845 2201 c-41 -19 -116 -107 -125 -147 -10 -43 -4 -95 15 -134 7 -14 18 -51 25 -84 18 -87 94 -229 149 -278 57 -51 115 -77 161 -72 19 2 65 6 102 8 126 9 214 73 278 200 42 83 45 115 25 215 -18 83 -21 88 -79 150 -74 76 -121 105 -206 126 -76 18 -316 29 -345 16z' },
  { key: '43', d: 'M5429 1747 c-63 -33 -89 -88 -89 -187 0 -41 -5 -132 -11 -202 -12 -125 -11 -128 15 -212 15 -47 32 -90 39 -96 74 -61 103 -81 123 -86 28 -7 172 29 227 58 100 50 211 223 223 345 6 50 3 69 -14 105 -28 57 -110 141 -164 169 -24 12 -66 37 -95 56 -59 39 -145 73 -184 73 -14 0 -46 -10 -70 -23z' },
  { key: '33', d: 'M2777 1697 c-363 -215 -397 -245 -412 -359 -6 -41 -3 -57 24 -113 17 -35 31 -67 31 -70 0 -16 95 -138 129 -164 43 -35 176 -97 225 -107 40 -7 139 35 161 70 8 12 30 68 50 123 l34 101 -2 230 -2 231 -29 30 c-52 54 -145 66 -209 28z' },
  { key: '42', d: 'M4925 1391 c-71 -44 -94 -94 -125 -275 -10 -62 -24 -132 -29 -156 -26 -112 -8 -228 45 -285 33 -37 36 -38 87 -32 28 3 119 28 200 55 224 76 294 134 283 238 -8 71 -45 131 -151 242 -63 66 -110 125 -123 154 -32 74 -119 102 -187 59z' },
  { key: '32', d: 'M3315 1393 c-28 -7 -54 -32 -75 -73 -10 -19 -67 -87 -126 -151 -155 -164 -188 -239 -148 -339 28 -70 150 -135 345 -184 144 -36 174 -35 223 11 34 31 40 43 41 82 1 25 5 75 9 111 7 56 4 76 -18 140 -14 42 -32 125 -41 185 -9 61 -24 126 -33 145 -31 64 -99 92 -177 73z' },
  { key: '41', d: 'M4384 1178 c-27 -13 -85 -144 -114 -258 -12 -46 -28 -100 -36 -120 -9 -19 -18 -72 -21 -117 -6 -78 -4 -85 21 -123 15 -23 39 -46 54 -52 62 -25 307 1 414 43 46 19 61 53 56 128 -5 62 -11 75 -97 218 -50 84 -102 177 -115 207 -13 29 -34 61 -47 70 -26 18 -83 20 -115 4z' },
  { key: '31', d: 'M3858 1158 c-14 -13 -35 -41 -46 -63 -10 -22 -50 -92 -87 -155 -129 -216 -141 -250 -114 -329 26 -75 202 -120 435 -111 71 2 104 61 104 179 -1 91 -11 129 -65 256 -26 61 -54 130 -61 155 -22 68 -37 82 -91 87 -39 3 -53 0 -75 -19z' }
]

export default {
  name: 'Denture',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      clientWidth: 100,
      clientHeight: 100,
      thresholds: {
        low: 0.33,
        increased: 0.66
      }
    }
  },
  methods: {
    getTooltip (tandId) {
      let result = `Element ${tandId}`

      if (this.data[tandId]) {
        if (this.data[tandId].probability_normalized <= this.thresholds.low) {
          result += '<br>Risico: Laag'
        } else if (this.data[tandId].probability_normalized <= this.thresholds.increased) {
          result += '<br>Risico: Verhoogd'
        } else {
          result += '<br>Risico: Hoog'
        }

        result += '<br><br>Risicofactoren:'
        result += '<ul>'

        if (this.data[tandId].e_tc_wortelkanaalbehandeling > 0) {
          result += '<li>Wortelkanaal</li>'
        }
        if (this.data[tandId].e_tc_stift > 0) {
          result += '<li>Stift</li>'
        }
        if (this.data[tandId].e_tc_opbouw > 0) {
          result += '<li>Opbouw</li>'
        }
        if (this.data[tandId].e_tc_vulling_4v > 0) {
          result += '<li>Viervlaksvulling</li>'
        } else if (this.data[tandId].e_tc_vulling_3v > 0) {
          result += '<li>Drievlaksvulling</li>'
        } else if (this.data[tandId].e_tc_vulling_2v > 0) {
          result += '<li>Tweevlaksvulling</li>'
        } else if (this.data[tandId].e_tc_vulling_1v > 0) {
          result += '<li>Eenvlaksvulling</li>'
        }
        if (this.data[tandId].aantal_vullignen_laatste_5_jaar > 0) {
          result += '<li>' + this.data[tandId].aantal_vullignen_laatste_5_jaar + 'x gevuld (in 5 jaar)</li>'
        }

        result += '</ul>'
      }
      return result
    },
    getColor (tandId) {
      if (!this.data[tandId]) {
        return '#FFF'
      } else {
        if (this.data[tandId].probability_normalized <= this.thresholds.low) {
          return '#FFF'
        } else if (this.data[tandId].probability_normalized <= this.thresholds.increased) {
          return '#F6C54B'
        } else if (this.data[tandId].probability_normalized > this.thresholds.increased) {
          return '#F25E68'
        }
      }
    }
  },
  computed: {
    transformUpper () {
      return 'translate(' + (this.clientWidth / 1.25) + ',' + this.clientHeight / 2.5 + ') scale(-' + (this.clientHeight / 15000) + ')'
    },
    transformLower () {
      return 'translate(' + (this.clientWidth / 1.25) + ',' + this.clientHeight / 1.1 + ') scale(-' + (this.clientHeight / 15000) + ')'
    }
  },
  mounted () {
    this.$watch(
      () => {
        this.clientWidth = this.$refs.denture.clientWidth
        this.clientHeight = this.$refs.denture.clientHeight
      }
    )
    var div = d3.select('.body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)

    const getColor = this.getColor
    const getTooltip = this.getTooltip
    d3.select('svg g#boven').selectAll('path')
      .data(elementen.filter(function (d) { return ['1', '2'].indexOf(d.key.substr(0, 1)) > -1 }), function (d) { return d.key })
      .enter()
      .append('g')
      .attr('class', 'pathGroup')
      .attr('id', function (d) { return 'element-' + d.key })
      .append('path')
      .attr('d', function (d) { return d.d })
      .style('fill', function (d, i) { return getColor(d.key) })
      .style('stroke', '#000000')
      .attr('transform', function (d) { return `rotate(${d.rotate})` })
      .style('stroke-width', '10')
      .on('mouseover', function (event, d) {
        div.transition()
          .duration(200)
          .style('opacity', 0.9)
        div.html(getTooltip(d.key))
          .style('left', (event.pageX) + 'px')
          .style('top', (event.pageY - 28) + 'px')
      })
      .on('mouseout', function (d) {
        div.transition()
          .duration(500)
          .style('opacity', 0)
      })

    d3.select('svg g#beneden').selectAll('path')
      .data(elementen.filter(function (d) { return ['3', '4'].indexOf(d.key.substr(0, 1)) > -1 }), function (d) { return d.key })
      .enter()
      .append('g')
      .attr('class', 'pathGroup')
      .attr('id', function (d) { return 'element-' + d.key })
      .append('path')
      .attr('d', function (d) { return d.d })
      .style('fill', function (d, i) { return getColor(d.key) })
      .style('stroke', '#000000')
      .attr('transform', function (d) { return `rotate(${d.rotate})` })
      .style('stroke-width', '10')
      .on('mouseover', function (event, d) {
        div.transition()
          .duration(200)
          .style('opacity', 0.9)
        div.html(getTooltip(d.key))
          .style('left', (event.pageX) + 'px')
          .style('top', (event.pageY - 28) + 'px')
      })
      .on('mouseout', function (d) {
        div.transition()
          .duration(500)
          .style('opacity', 0)
      })
  }
}

</script>
