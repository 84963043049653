<template>
  <div class="modal fade show" style="display:block;" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="feedback-content">
      <div class="feedback-header">Feedback</div>
      <div class="feedback-body">
        <li class="feedback-item">Caries
          <font-awesome-icon class="icon down" icon="thumbs-down" :style="{color: Feedback.CariesLiked === false ? '#EF476F' : 'grey'}" @click="Feedback.CariesLiked=false"/>
          <font-awesome-icon class="icon up" icon="thumbs-up" :style="{color: Feedback.CariesLiked === true ? '#00838A' : 'grey'}" @click="Feedback.CariesLiked=true"/>
        </li>
        <li class="feedback-item">Parodontitis
          <font-awesome-icon class="icon down" icon="thumbs-down" :style="{color: Feedback.ParoOntwikkelenLiked === false || Feedback.ParoVerslecteringLiked === false ? '#EF476F' : 'grey'}" @click="ParoLiked(false)" />
          <font-awesome-icon class="icon up" icon="thumbs-up" :style="{color: Feedback.ParoOntwikkelenLiked === true || Feedback.ParoVerslecteringLiked === true ? '#00838A' : 'grey'}" @click="ParoLiked(true)" />
        </li>
        <li class="feedback-item">Duurzaamheid
          <font-awesome-icon class="icon down" icon="thumbs-down" :style="{color: Feedback.KroonLiked === false ? '#EF476F' : 'grey'}" @click="Feedback.KroonLiked=false"/>
          <font-awesome-icon class="icon up" icon="thumbs-up"  :style="{color: Feedback.KroonLiked === true ? '#00838A' : 'grey'}" @click="Feedback.KroonLiked=true"/>
        </li>
        <li class="feedback-item">
          <input type="text" v-model="Feedback.text" id="feedback" class="feedback-input form-control" placeholder="Toevoeging" />
        </li>
      </div>
      <div class="feedback-buttons">
        <button class="button" @click="$emit('close')">Sluit</button>
        <button class="button verzend" @click="SubmitFeedback()">Verzend</button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'FeedbackModal',
  props: {
    PatientResult: {
      type: Object
    }
  },
  data () {
    return {
      Feedback: {
        CariesLiked: null,
        ParoOntwikkelenLiked: null,
        ParoVerslecteringLiked: null,
        KroonLiked: null,
        text: null
      }
    }
  },
  methods: {
    ParoLiked (value) {
      if (Object.keys(this.PatientResult.paroontwikkelen).length !== 0) {
        this.Feedback.ParoOntwikkelenLiked = value
      } else if (Object.keys(this.PatientResult.paroverlechtering).length !== 0) {
        this.Feedback.ParoVerslecteringLiked = value
      }
    },
    SubmitFeedback () {
      if (this.Feedback.CariesLiked !== null) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/feedback/`, { liked: this.Feedback.CariesLiked, content_type: 19, object_id: this.PatientResult.caries.id, more: this.Feedback.text }, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })
          .then(
            (response) => {
            },
            (error) => {
              this.errorMessage = error.response.data.non_field_errors[0]
            }
          )
      }
      if (this.Feedback.ParoOntwikkelenLiked !== null) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/feedback/`, { liked: this.Feedback.ParoOntwikkelenLiked, content_type: 17, object_id: this.PatientResult.paroontwikkelen.id, more: this.Feedback.text }, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })
          .then(
            (response) => {
            },
            (error) => {
              this.errorMessage = error.response.data.non_field_errors[0]
            }
          )
      }
      if (this.Feedback.ParoVerslecteringLiked !== null) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/feedback/`, { liked: this.Feedback.ParoVerslecteringLiked, content_type: 16, object_id: this.PatientResult.paroverlechtering.id, more: this.Feedback.text }, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })
          .then(
            (response) => {
            })
          .catch(
            (error) => {
              console.log(error.message)
            }
          )
      }
      if (this.Feedback.KroonLiked !== null) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/feedback/`, { liked: this.Feedback.KroonLiked, content_type: 18, object_id: this.PatientResult.kroons.id, more: this.Feedback.text }, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })
          .then(
            (response) => {
            },
            (error) => {
              this.errorMessage = error.response.data.non_field_errors[0]
            }
          )
      }
      if (!Object.values(this.Feedback).every(o => o === null)) {
        this.$notify({ type: 'success', text: 'Bedankt voor uw feedback' })
        this.$emit('close')
      } else {
        this.$notify({ type: 'warning', text: 'Er is geen feedback gegeven' })
      }
      this.Feedback.CariesLiked = null
      this.Feedback.ParoOntwikkelenLiked = null
      this.Feedback.ParoVerslecteringLiked = null
      this.Feedback.KroonLiked = null
      this.Feedback.text = null
    }
  }
}

</script>

<style scoped>

.feedback-content {
  margin: 20px 20px 0px 20px;
  padding: 20px 15px 15px 15px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), -1px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-family: "Nexa";
  font-style: normal;
  text-color: black;
  text-align: left;
}

.feedback-header {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-left: 9px;
}

.feedback-body {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.feedback-item {
    display: block;
    padding: 6px 15px;
    padding-left: 9px;
    margin-bottom: -1px;
    line-height: 1em;
    margin-top: 1px;
}

.icon {
  float: right;
  font-size: 19px;
  margin-left: 10px;
  vertical-align: middle;
}

.up {
  margin-top: -4px;
}

.down {
  margin-top: 1px;
}

.up:hover {
  color: #00838A !important;
  cursor: pointer;
}

.down:hover {
  color: #EF476F !important;
  cursor: pointer;
}

.feedback-input {
  margin-top: 10px;
}

.feedback-buttons {
  display: flex;
  justify-content: flex-end;
}

.button {
  font-size: 1em;
  color: white;
  background: #6A8E9E;
  border: none;
  width: 30%;
  height: 45px;
  font-family: Nexa;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: 0.3s;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 54px;
}

.button:hover {
  opacity: 0.8;
}

.verzend {
  background: #00838A;
}

</style>
