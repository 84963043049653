import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  name: 'bar-chart',
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  mounted () {
    this.renderChart(
      this.chartData,
      {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: 'black',
              fontFamily: 'Nexa',
              fontWeight: 'light'
            }
          }],
          xAxes: [{
            ticks: {
              suggestedMax: 1,
              suggestedMin: -1,
              display: false
            },
            gridLines: {
              color: 'white',
              zeroLineColor: 'lightgrey'
            }
          }]
        }
      }
    )
  }
}
