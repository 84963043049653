<template>
  <div class="text-center w-100 background">
    <div class="white-space">
    </div>
    <div v-if="PatientResult.id !== null">
      <div class="model-card">
        <div v-if="PatientResult.caries.probability_normalized !== null && Object.keys(PatientResult.caries).length !== 0">
          <div class="column">
            <div class="model-title row-sm">Cariës</div>
            <div class="model-risk-text row-sm">{{getLaagVerhoogdHoog(PatientResult.caries.probability_normalized)}} risico</div>
            <div class="progressbar row-sm">
              <div class="progressbar-background" style="width:100%"></div>
              <div class="progressbar-progress" :style="`width:${PatientResult.caries.probability_normalized*100}%; background:${this.getColor(PatientResult.caries.probability_normalized)}`"></div>
            </div>
            <div class="row-sm more-info-div">
              <more-info-modal v-if="showMoreInfoCaries"
                :PatientResult="PatientResult"
                model="caries"
                :chartData="formatBarChartData(rf_caries_labels, rf_caries_values, rf_caries_bgcolor)"
                />
              <div class="more-info-button-div">
                <button class="more-info-button row-sm" @click="showMoreInfoCaries=!showMoreInfoCaries">lees {{getLeesMeerMinderTekst(showMoreInfoCaries)}}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="column">
            <div class="model-title row-sm">Cariës</div>
            <div class="model-risk-text row-sm" style="font-style: italic;">Risico niet beschikbaar</div>
            <div class="progressbar row-sm">
              <div class="progressbar-background" style="width:100%"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="model-card">
        <div v-if="PatientResult.paroontwikkelen.probability_normalized !== null && Object.keys(PatientResult.paroontwikkelen).length !== 0 && PatientResult.age > 12">
          <div class="column">
            <div class="model-title row-sm">Parodontitis <sub>(ontwikkelen)</sub></div>
            <div class="model-risk-text row-sm">{{getLaagVerhoogdHoog(PatientResult.paroontwikkelen.probability_normalized)}} risico</div>
            <div class="progressbar row-sm">
              <div class="progressbar-background" style="width:100%"></div>
              <div class="progressbar-progress" :style="`width:${PatientResult.paroontwikkelen.probability_normalized*100}%; background:${this.getColor(PatientResult.paroontwikkelen.probability_normalized)}`"></div>
            </div>
            <div class="row-sm more-info-div">
              <more-info-modal v-if="showMoreInfoParoOnt"
                :PatientResult="PatientResult"
                model="paroontwikkelen"
                :chartData="formatBarChartData(rf_paro_ont_labels, rf_paro_ont_values, rf_paro_ont_bgcolor)"
                />
              <div class="more-info-button-div">
                <button class="more-info-button row-sm" @click="showMoreInfoParoOnt=!showMoreInfoParoOnt">lees {{getLeesMeerMinderTekst(showMoreInfoParoOnt)}}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="PatientResult.paroverlechtering.probability_normalized !== null && Object.keys(PatientResult.paroverlechtering).length !== 0 && PatientResult.age > 12">
          <div class="column">
            <div class="model-title row-sm">Parodontitis <sub>(achteruitgang)</sub></div>
            <div class="model-risk-text row-sm">{{getLaagVerhoogdHoog(PatientResult.paroverlechtering.probability_normalized)}} risico</div>
            <div class="progressbar row-sm">
              <div class="progressbar-background" style="width:100%"></div>
              <div class="progressbar-progress" :style="`width:${PatientResult.paroverlechtering.probability_normalized*100}%; background:${this.getColor(PatientResult.paroverlechtering.probability_normalized)}`"></div>
            </div>
            <div class="row-sm more-info-div">
              <more-info-modal v-if="showMoreInfoParoVer"
                :PatientResult="PatientResult"
                model="paroverlechtering"
                :chartData="formatBarChartData(rf_paro_ver_labels, rf_paro_ver_values, rf_paro_ver_bgcolor)"
                />
              <div class="more-info-button-div">
                <button class="more-info-button row-sm" @click="showMoreInfoParoVer=!showMoreInfoParoVer">lees {{getLeesMeerMinderTekst(showMoreInfoParoVer)}}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="column">
            <div class="model-title row-sm">Parodontitis</div>
            <div class="model-risk-text row-sm" style="font-style: italic;">Risico niet beschikbaar</div>
            <div class="progressbar row-sm">
              <div class="progressbar-background" style="width:100%"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="model-card">
        <div v-if="PatientResult.age > 12">
          <div class="column">
            <div class="model-title row-sm">Duurzaamheid</div>
            <denture :data="PatientResult.kroons.data" :key="PatientResult.id_patient"/>
            <div class="row-sm more-info-div">
              <more-info-duurzaamheid-modal v-if="showMoreInfoDuurzaamheid"
                                            :PatientResult="PatientResult"
                                            model="duurzaamheid"
                                            />
              <div class="more-info-button-div">
                <button class="more-info-button row-sm" @click="showMoreInfoDuurzaamheid=!showMoreInfoDuurzaamheid">lees {{getLeesMeerMinderTekst(showMoreInfoDuurzaamheid)}}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="column">
            <div class="model-title row-sm">Duurzaamheid</div>
            <div class="model-risk-text row-sm" style="font-style: italic;">Risico niet beschikbaar</div>
          </div>
        </div>
      </div>
      <pdf-modal v-if="showPDFmodal" @close="showPDFmodal=false" :id_patient="PatientResult.id_patient" :id_patient_search="PatientResult.id_patient_searchbar" :key="PatientResult.id_patient" />
      <feedback-modal v-if="showFeedbackmodal" @close="showFeedbackmodal=false" :PatientResult="PatientResult" :key="PatientResult.id_patient" />
      <opt-out-modal v-if="showOptoutmodal" @close="showOptoutmodal=false" :id_patient="PatientResult.id_patient" :id_patient_search="PatientResult.id_patient_searchbar" :key="PatientResult.id_patient" />
    </div>
    <div class="button-div">
      <button type="button" class="button feedback-button" v-if="PatientResult.id !== null" @click="showFeedbackmodal=true">Feedback</button>
      <button type="button" class="button verwijder-button" v-if="PatientResult.id !== null" @click="showOptoutmodal=true">Opt-out</button>
    </div>
    <div class="logo-div">
      <img class="logo" src="../assets/Part_of_Cureaos_RGB.png">
    </div>
  </div>
</template>

<script>

import Denture from '@/components/Denture.vue'
import FeedbackModal from '@/components/FeedbackModal.vue'
import OptOutModal from '@/components/OptOutModal.vue'
import MoreInfoModal from '@/components/MoreInfoModal.vue'
import MoreInfoDuurzaamheidModal from '@/components/MoreInfoDuurzaamheidModal.vue'
import axios from 'axios'
export default {
  name: 'Sidebar',
  components: { Denture, FeedbackModal, OptOutModal, MoreInfoModal, MoreInfoDuurzaamheidModal },
  data () {
    return {
      thres: [0.33, 0.66],
      PatientResult: {
        id: null,
        clinic: null,
        kroon: [],
        caries: {},
        paroontwikkelen: {},
        paroverlechtering: {}
      },
      submitted: false,
      patient: {
        id: null
      },
      showFeedbackmodal: false,
      showOptoutmodal: false,
      showMoreInfoCaries: false,
      showMoreInfoParoOnt: false,
      showMoreInfoParoVer: false,
      showMoreInfoDuurzaamheid: false
    }
  },
  mounted () {
    axios.get(`${process.env.VUE_APP_API_URL}/api/patient/${this.$route.params.praktijk}-${this.$route.params.patient}/`, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } }).then(
      (response) => {
        this.submitted = true
        this.PatientResult = response.data
      },
      (error) => {
        this.$set(this.PatientResult, 'id', null)
        this.$notify({ type: 'error', text: error.response.data.error })
      }
    )
  },
  computed: {
    rf_paro_ont_labels () {
      return this.PatientResult.paroontwikkelen.explanation.slice(0, 6).map(d => [d.label, '(' + d.additional_label + ')'])
    },
    rf_paro_ont_values () {
      return this.PatientResult.paroontwikkelen.explanation.slice(0, 6).map(d => d.value)
    },
    rf_paro_ont_bgcolor () {
      return this.PatientResult.paroontwikkelen.explanation.slice(0, 6).map(d => this.getColorBar(d.value))
    },
    rf_paro_ver_labels () {
      return this.PatientResult.paroverlechtering.explanation.slice(0, 6).map(d => [d.label, '(' + d.additional_label + ')'])
    },
    rf_paro_ver_values () {
      return this.PatientResult.paroverlechtering.explanation.slice(0, 6).map(d => d.value)
    },
    rf_paro_ver_bgcolor () {
      return this.PatientResult.paroverlechtering.explanation.slice(0, 6).map(d => this.getColorBar(d.value))
    },
    rf_caries_labels () {
      return this.PatientResult.caries.explanation.slice(0, 6).map(d => [d.label, '(' + d.additional_label + ')'])
    },
    rf_caries_values () {
      return this.PatientResult.caries.explanation.slice(0, 6).map(d => d.value)
    },
    rf_caries_bgcolor () {
      return this.PatientResult.caries.explanation.slice(0, 6).map(d => this.getColorBar(d.value))
    }
  },
  methods: {
    getLaagVerhoogdHoog (prob) {
      if (prob <= this.thres[0]) {
        return 'laag'
      } else if (prob <= this.thres[1]) {
        return 'verhoogd'
      } else {
        return 'hoog'
      }
    },
    getColor (prob) {
      if (prob <= this.thres[0]) {
        return '#00838A'
      } else if (prob <= this.thres[1]) {
        return '#FEB701'
      } else {
        return '#EF476F'
      }
    },
    getLeesMeerMinderTekst (boolean) {
      if (boolean) {
        return 'minder'
      } else {
        return 'meer'
      }
    },
    formatBarChartData (labels, datasets, bgcolor) {
      return {
        labels: labels,
        datasets: [
          {
            label: 'Impact',
            backgroundColor: bgcolor,
            data: datasets
          }
        ]
      }
    },
    getColorBar (v) {
      if (v <= 0) {
        return '#00838A'
      } else {
        return '#EF476F'
      }
    }
  }
}
</script>

<style scoped>

.background {
  overflow: hidden;
}

.white-space {
  height: 1px;
  margin-top: 15px;
}

.model-card {
  margin: 10px 10px 0px 10px;
  padding: 20px 15px 15px 15px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), -1px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.model-title {
  font-family: "Nexa";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: black;
  margin-bottom: 15px;
}

.model-risk-text {
  font-family: "Nexa";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #6A8E9E;
  margin-bottom: 10px;
}

.model-risk-text:first-letter {
  text-transform: capitalize;
}

.progressbar {
  position: relative;
  margin-bottom: 20px;
}

.progressbar-background {
  height: 6px;
  border-radius: 5px;
  background: #E6E6E6;
}

.progressbar-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 5px;
}

.more-info-button-div {
  text-align: right;
}

.more-info-button {
  font-family: "GilRoy";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6A8E9E;
  background: white;
  border: none;
  padding: 0;
  transition: 0.3s;
}

.more-info-button:hover {
  color: #364b53;
}

.button-div {
  margin: 30px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 200px;
  height: 45px;
  font-family: Nexa;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6A8E9E;
  background: transparent;
  transition: 0.3s;
  margin-bottom: 15px;
}

.feedback-button {
  border: 1px solid rgba(106, 142, 158, 0.5);
  box-sizing: border-box;
  border-radius: 54px;
}

.feedback-button:hover {
  color: #364b53;
  border-color: #364b53;
}

.verwijder-button {
  border: none;
  text-decoration: underline;
}

.verwijder-button:hover {
  color: #364b53;
}

.logo-div {
  margin: 0% 0 20px 0;
  bottom: 0;
  width: 100%;
  /*Make Curaeos logo invisible. Replace with Colosseum Dental in future.*/
  opacity: 0%;
}

.logo {
  height: 88px;
  border-radius: 44px 0 0 44px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), -1px 2px 12px rgba(0, 0, 0, 0.1);
}

</style>
