<template>
  <div id="app">
    <notifications position="bottom center"  width="60%" classes="notifications"/>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>

@font-face {
  font-family: "GilRoy";
  src: url('./assets/fonts/Gilroy-Light.otf') format("truetype");
}

@font-face {
  font-family: "GilRoyExtraBold";
  src: url('./assets/fonts/Gilroy-ExtraBold.otf') format("truetype");
}

@font-face {
  font-family: "Nexa";
  src: url('./assets/fonts/NexaRegular.otf') format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: url('./assets/fonts/gotham-book.woff') format('woff'),
       url('./assets/fonts/gotham-book.woff2') format('woff2'),
       url('./assets/fonts/gotham-book.ttf') format('truetype');
  font-weight: normal;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
}

body {
  background-color: rgb(106, 142, 158, 0.1) !important;
}

body::-webkit-scrollbar {
  width: 0px;
}

.notifications {
  padding: 20px;
  margin: 20px;
  font-family: Nexa;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  background: #44A4FC;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), -1px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.notifications.warn {
  background: #FEB701;
}

.notifications.success {
  background: #00838A;
}

.notifications.error {
  background: #EF476F;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*height: 100%;*/
  /*overflow-y: auto;*/
  background-color: #6A8E9E important!;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.modal {
  background-color: rgba(0, 0, 0, .5);
}

</style>
