<template>
  <div class="meer-info-modal column">
    <strong class="meer-info-title row-sm">Risicofactoren</strong>
    <p class="meer-info-description row-sm">
      De patiënt heeft een <strong>{{ getLaagVerhoogdHoog(PatientResult[model].probability_normalized) }}</strong> risico op {{ modelNameSpecific }}. Dit is berekend op basis van onderstaande kenmerken die een <strong style="color: #00838A;">verlagend</strong> of <strong style="color: #EF476F;">verhogend</strong> effect op dit risico hebben.
    </p>
    <bar-chart class="row-sm" :chartData="chartData" :key="PatientResult.id_patient">
    </bar-chart>
    <div class="meer-info-zorgstraat row-sm" v-if="PatientResult[this.model].probability_normalized > this.thres[0] || this.model == 'paroverlechtering'">
      <br>
      Zorgstraat passend bij een <strong>{{ getLaagVerhoogdHoog(PatientResult[model].probability_normalized) }}</strong> risico op {{ modelNameSpecific }}:
      <ul id="advise">
        <li class="advise-items" v-for="(advise, idx) in Advise" :key="idx">
          <a href="javascript:void(0)" @click="getPDF(advise[1])">{{ advise[0] }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import BarChart from '@/components/Charts/BarChart'
import axios from 'axios'

export default {
  name: 'MoreInfoModal',
  components: { BarChart },
  props: {
    PatientResult: {
      type: Object
    },
    model: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object
    }
  },
  data () {
    return {
      thres: [0.33, 0.66],
      Advise: []
    }
  },
  computed: {
    modelName () {
      return (this.model === 'caries' ? 'caries' : 'parodontitis')
    },
    modelNameSpecific () {
      if (this.model === 'paroverlechtering') {
        return 'achteruitgang parodontitis'
      } else if (this.model === 'paroontwikkelen') {
        return 'parodontitis'
      } else {
        return 'cariës'
      }
    }
  },
  methods: {
    getLaagVerhoogdHoog (prob) {
      if (prob <= this.thres[0]) {
        return 'laag'
      } else if (prob <= this.thres[1]) {
        return 'verhoogd'
      } else {
        return 'hoog'
      }
    },
    getPDF (pdfName) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/api/zorgstraat/download/`, { filename: pdfName }, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` }, responseType: 'arraybuffer' })
        .then(
          (response) => {
            var blob = new Blob([response.data], { type: 'application/pdf' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.target = '_blank'
            link.click()
          }
        )
    }
  },
  mounted () {
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/patient/${this.$route.params.praktijk}-${this.$route.params.patient}/more_info/?model=${this.model}`, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })

    if (this.model === 'caries') {
      if (this.PatientResult[this.model].probability_normalized > this.thres[0]) {
        this.Advise.push(['Parodontale preventie', 'paro_preventie'])
        this.Advise.push(['Parodontale preventie nazorg', 'paro_preventie_evaluatie'])
      }
    }
    if (this.model === 'paroontwikkelen') {
      if (this.PatientResult[this.model].probability_normalized > this.thres[0]) {
        this.Advise.push(['Parodontale preventie', 'paro_preventie'])
        this.Advise.push(['Parodontale preventie nazorg', 'paro_preventie_evaluatie'])
      }
    }
    if (this.model === 'paroverlechtering') {
      this.Advise.push(['Parodontaal onderzoek', 'paro_onderzoek'])
      this.Advise.push(['Initiële Parodontale behandeling', 'paro_initiele_behandeling'])
      this.Advise.push(['Tussentijdse parodontale beoordeling', 'paro_tussentijdse_beoordeling'])
      this.Advise.push(['Parodontale herbeoordeling', 'paro_herbeoordeling'])
      this.Advise.push(['Parodontale nazorg', 'paro_nazorg'])
      this.Advise.push(['Parodontale evaluatie', 'paro_evaluatie'])
    }
    if (this.model === 'duurzaamheid') {
      this.Advise.push(['Kroon', 'kroon'])
    }
  }
}

</script>

<style scoped>

.meer-info-modal {
  font-family: Nexa;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 30px;
}

ul {
  padding: 10px 20px 10px 20px;
}

li {
  padding-bottom: 5px;
  color: black;
}

a {
  color: black;
  text-decoration: underline;
  transition: 0.2s;
}

a:hover {
  color: #6A8E9E;
}

</style>
