<template>
  <div class="modal fade show" style="display:block;" id="deletePatientData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="optout-content">
      <div class="optout-header">Patiënt verwijderen</div>
      <div class="optout-body">
        Patiënt {{ strip_patient_id(id_patient_search) }} geeft geen toestemming voor het gebruik van zijn/haar data in dit systeem.<br><br><strong>Wilt u de patiënt verwijderen?</strong>
      </div>
      <div class="optout-buttons">
          <button class="button" @click="$emit('close')">Terug</button>
          <button class="button verwijderen" data-dismiss="modal" @click="DeletePatient()">Verwijderen</button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'OptOutModal',
  props: {
    id_patient: {
      type: String
    },
    id_patient_search: {
      type: String
    }
  },
  methods: {
    strip_patient_id (id) {
      if (id != null) {
        return id.split('-')[1] + '-' + id.split('-')[2]
      }
    },
    DeletePatient () {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/patient/${this.$route.params.praktijk}-${this.$route.params.patient}/opt_out/`, { headers: { Authorization: `Token ${this.$route.query.token}`, 'X-userid': `${this.$route.query.userid}` } })
        .then(() => { this.$notify({ type: 'success', text: 'De patiëntdata zal spoedig worden verwijderd' }) }, this.$emit('close'))
    }
  }
}

</script>

<style scoped>

.optout-content {
  margin: 20px 20px 0px 20px;
  padding: 20px 15px 15px 15px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), -1px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-family: "Nexa";
  font-style: normal;
  text-color: black;
  text-align: left;
}

.optout-header {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
}

.optout-body {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.optout-buttons {
  display: flex;
  justify-content: flex-end;
}

.button {
  font-size: 1em;
  color: white;
  background: #6A8E9E;
  border: none;
  width: 30%;
  height: 45px;
  font-family: Nexa;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: 0.3s;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 54px;
}

.button:hover {
  opacity: 0.8;
}

.verwijderen {
  background: #EF476F;
  width: 35%;
}

</style>
